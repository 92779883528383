.h2 {
    font-family: 'Avenir LT Std';
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #FFFFFF;
}

.span {
    font-style: normal;
    line-height: 31px;
}

.hashtag {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 700;
    /* font-size: 50px; */

    text-align: center;
}

.p {
    font-family: 'Avenir LT Std';
    font-weight: 350;
    line-height: 24px;
}