@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Avenir LT Std;
  font-weight: 350;
  src: url("../public/Avenir_LT_Std/Avenir\ LT\ Std\ 35\ Light/Avenir\ LT\ Std\ 35\ Light.otf") format("opentype");
}

@font-face {
  font-family: Avenir LT Std;
  src: url("../public/Avenir_LT_Std/Avenir\ LT\ Std\ 65\ Medium/Avenir\ LT\ Std\ 65\ Medium.otf") format("opentype");
}

@font-face {
  font-family: Avenir LT Std;
  font-weight: bold;
  src: url("../public/Avenir_LT_Std/Avenir\ LT\ Std\ 85\ Heavy/Avenir\ LT\ Std\ 85\ Heavy.otf") format("opentype");
}


@font-face {
  font-family: Amatic SC;
  font-weight: bold;
  src: url("../public/Amatic_SC/AmaticSC-Bold.ttf") format("opentype");
}

body {
  margin: 0;
  font-family: Avenir LT Std, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color:  #e34462;
}
/* 
code {
  font-family: Avenir LT Std, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
